<template>
  <div class="register-funnel">
    <div class="register-funnel__main-block">
      <div class="register-funnel__main-block__inner">
        <div class="register-funnel__main-block__heading">
          <a href="https://www.letsconnect.at/" class="register-funnel__main-block__heading__logo-wrapper">
            <img src="@/assets/images/register-funnel/vega_logo.svg" alt="Logo" class="register-funnel__main-block__heading__logo-img" />
          </a>

          <div v-if="currentStep === 1 && !IS_LOTTERY_WINNER" class="register-funnel__main-block__heading__auth">
            <div class="register-funnel__main-block__heading__auth__text">{{ $t('vue.alreadyHaveAccount') }}</div>

            <img src="@/assets/images/register-funnel/lock.png" alt="Secure" class="register-funnel__main-block__heading__auth__icon" />

            <div class="register-funnel__main-block__heading__auth__link" @click="$router.push('/pages/login')">
              {{ $t('vue.login') }}
            </div>
          </div>
        </div>

        <div class="register-funnel__main-block__body">
          <template v-if="currentStep === 4 && company && company.IS_LOTTERY_WINNER">
            <lottery-congrats-message />
          </template>

          <div class="register-funnel__main-block__flow">
            <div v-if="isBackButton" class="register-funnel__main-block__flow__action-buttons">
              <div class="register-funnel__main-block__flow__action-button" @click="prevStep">
                <ArrowLongIcon class="mr-2" color="rgb(135, 136, 148)" :direction="'left'" />

                {{ $t('vue.back') }}
              </div>
            </div>

            <div v-if="!isOpenedFeaturesListOnMobile && !showVegaActivationMessage" class="register-funnel__main-block__flow__progress-bar-wrapper">
              <div
                class="register-funnel__main-block__flow__progress-bar"
                :style="{
                  width: `${(currentStep / (totalCountOfSteps + 1)) * 100}%`
                }"
              />
            </div>

            <template v-if="currentStep === 1">
              <div
                class="register-funnel__main-block__flow__heading"
                :class="{
                  'register-funnel__main-block__flow__heading--sized': IS_LOTTERY_WINNER
                }"
              >
                <div
                  class="register-funnel__main-block__flow__heading__title"
                  :class="{
                    'register-funnel__main-block__flow__heading__title--small': IS_LOTTERY_WINNER
                  }"
                >
                  {{ IS_LOTTERY_WINNER ? $t('vue.registerFunnelDiscount') : $t('vue.registerFunnelStartYourFreeTrial') }}
                </div>
              </div>

              <div class="register-funnel__main-block__flow__content">
                <funnel-social-login type="register" :IS_LOTTERY_WINNER="IS_LOTTERY_WINNER" @set-lottery-data="setLotteryData" />

                <div class="register-funnel__main-block__flow__content__separator">
                  <span class="register-funnel__main-block__flow__content__separator__label">{{ $t('vue.or').toLowerCase() }}</span>
                </div>

                <div class="register-funnel__main-block__flow__content__form">
                  <div class="register-funnel__main-block__flow__content__form__fields">
                    <div class="register-funnel__main-block__flow__content__form__fields__field">
                      <div class="register-funnel__main-block__flow__content__form__fields__field__inner">
                        <input
                          ref="name"
                          type="text"
                          class="register-funnel__main-block__flow__content__form__fields__field__input"
                          v-model="name"
                          v-validate="'validateStringForSpecialCharacters'"
                          data-vv-validate-on="blur"
                          :name="$t('vue.name')"
                          :label-placeholder="$t('vue.name')"
                        />

                        <div
                          class="register-funnel__main-block__flow__content__form__fields__field__label"
                          :class="{
                            'register-funnel__main-block__flow__content__form__fields__field__label--moved': name && name.length > 0
                          }"
                          @click="$refs.name.focus()"
                        >
                          {{ $t('inputs.enterYourName') }}
                        </div>
                      </div>

                      <span v-if="errors.first($t('vue.name'))" class="text-danger text-lg flex items-center" style="margin-top: 10px">
                        <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first($t('vue.name')) }}
                      </span>
                    </div>

                    <div class="register-funnel__main-block__flow__content__form__fields__field">
                      <div class="register-funnel__main-block__flow__content__form__fields__field__inner">
                        <input
                          ref="email"
                          type="email"
                          class="register-funnel__main-block__flow__content__form__fields__field__input"
                          v-model="email"
                          v-validate="'required|email'"
                          data-vv-validate-on="blur"
                          :name="$t('inputs.email')"
                          :label-placeholder="$t('inputs.email')"
                          @keyup.enter="nextStep"
                          @change="isUniqueEmailError = false"
                        />

                        <div
                          class="register-funnel__main-block__flow__content__form__fields__field__label"
                          :class="{
                            'register-funnel__main-block__flow__content__form__fields__field__label--moved': email && email.length > 0
                          }"
                          @click="$refs.email.focus()"
                        >
                          {{ $t('inputs.enterYourEmail') }}
                        </div>
                      </div>

                      <span v-if="errors.first($t('inputs.email'))" class="text-danger text-lg flex items-center" style="margin-top: 10px">
                        <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first($t('inputs.email')) }}
                      </span>

                      <span v-else-if="isUniqueEmailError" class="text-danger text-lg flex items-center" style="margin-top: 10px">
                        <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ $t('vue.emailAlreadyInUse') }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <template v-if="currentStep === 2">
              <form>
                <div class="register-funnel__main-block__flow__heading">
                  <div class="register-funnel__main-block__flow__heading__title">{{ $t('vue.niceToMeetYou') }} {{ name }}!</div>
                  <div class="register-funnel__main-block__flow__heading__description">{{ $t('vue.letsSetupYourPassword') }}</div>
                </div>

                <div class="register-funnel__main-block__flow__content">
                  <div class="register-funnel__main-block__flow__content__form">
                    <div class="register-funnel__main-block__flow__content__form__fields">
                      <div class="register-funnel__main-block__flow__content__form__fields__field">
                        <div class="register-funnel__main-block__flow__content__form__fields__field__inner">
                          <input
                            ref="password"
                            type="password"
                            v-model="password"
                            autocomplete="on"
                            class="register-funnel__main-block__flow__content__form__fields__field__input"
                            v-validate.continues="'required|min:6|max:128|atLeastOneNumber|atLeastOneUppercase|atLeastOneSpecialCharacter'"
                            :name="$t('inputs.password')"
                            :label-placeholder="$t('inputs.password')"
                            @keyup.enter="nextStep"
                          />

                          <div
                            class="register-funnel__main-block__flow__content__form__fields__field__label"
                            :class="{
                              'register-funnel__main-block__flow__content__form__fields__field__label--moved': password && password.length > 0
                            }"
                            @click="$refs.password.focus()"
                          >
                            {{ $t('inputs.password') }}
                          </div>
                        </div>

                        <span
                          v-if="
                            errors &&
                            errors.items &&
                            errors.items.find((item) => item.field === $t('inputs.password') && ['required', 'max'].includes(item.rule))
                          "
                          class="text-danger text-lg flex items-center"
                          style="margin-top: 10px"
                        >
                          <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first($t('inputs.password')) }}
                        </span>

                        <div class="register-funnel__main-block__flow__content__form__fields__field__rules-wrapper">
                          <div
                            class="register-funnel__main-block__flow__content__form__fields__field__rule"
                            :class="{
                              'register-funnel__main-block__flow__content__form__fields__field__rule--valid': password.length >= 6,
                              'register-funnel__main-block__flow__content__form__fields__field__rule--invalid':
                                submitPasswordStep &&
                                errors &&
                                errors.items &&
                                errors.items.find((item) => item.field === $t('inputs.password') && ['min'].includes(item.rule))
                            }"
                          >
                            <check-icon
                              v-if="password.length >= 6"
                              color="rgb(65, 184, 131)"
                              class="register-funnel__main-block__flow__content__form__fields__field__rule__icon"
                              :width="16"
                              :height="16"
                            />

                            {{ $t('inputs.minimumCharacters') }}
                          </div>

                          <div
                            class="register-funnel__main-block__flow__content__form__fields__field__rule"
                            :class="{
                              'register-funnel__main-block__flow__content__form__fields__field__rule--valid':
                                password.length &&
                                !(
                                  errors &&
                                  errors.items &&
                                  errors.items.find((item) => item.field === $t('inputs.password') && ['atLeastOneUppercase'].includes(item.rule))
                                ),
                              'register-funnel__main-block__flow__content__form__fields__field__rule--invalid':
                                submitPasswordStep &&
                                errors &&
                                errors.items &&
                                errors.items.find((item) => item.field === $t('inputs.password') && ['atLeastOneUppercase'].includes(item.rule))
                            }"
                          >
                            <check-icon
                              v-if="
                                password.length &&
                                !(
                                  errors &&
                                  errors.items &&
                                  errors.items.find((item) => item.field === $t('inputs.password') && ['atLeastOneUppercase'].includes(item.rule))
                                )
                              "
                              color="rgb(65, 184, 131)"
                              class="register-funnel__main-block__flow__content__form__fields__field__rule__icon"
                              :width="16"
                              :height="16"
                            />

                            {{ $t('inputs.atLeastUppercase') }}
                          </div>

                          <div
                            class="register-funnel__main-block__flow__content__form__fields__field__rule"
                            :class="{
                              'register-funnel__main-block__flow__content__form__fields__field__rule--valid':
                                password.length &&
                                !(
                                  errors &&
                                  errors.items &&
                                  errors.items.find((item) => item.field === $t('inputs.password') && ['atLeastOneNumber'].includes(item.rule))
                                ),
                              'register-funnel__main-block__flow__content__form__fields__field__rule--invalid':
                                submitPasswordStep &&
                                errors &&
                                errors.items &&
                                errors.items.find((item) => item.field === $t('inputs.password') && ['atLeastOneNumber'].includes(item.rule))
                            }"
                          >
                            <check-icon
                              v-if="
                                password.length &&
                                !(
                                  errors &&
                                  errors.items &&
                                  errors.items.find((item) => item.field === $t('inputs.password') && ['atLeastOneNumber'].includes(item.rule))
                                )
                              "
                              color="rgb(65, 184, 131)"
                              class="register-funnel__main-block__flow__content__form__fields__field__rule__icon"
                              :width="16"
                              :height="16"
                            />

                            {{ $t('inputs.atLeastNumber') }}
                          </div>

                          <div
                            class="register-funnel__main-block__flow__content__form__fields__field__rule"
                            :class="{
                              'register-funnel__main-block__flow__content__form__fields__field__rule--valid':
                                password.length &&
                                !(
                                  errors &&
                                  errors.items &&
                                  errors.items.find((item) => item.field === $t('inputs.password') && ['atLeastOneSpecialCharacter'].includes(item.rule))
                                ),
                              'register-funnel__main-block__flow__content__form__fields__field__rule--invalid':
                                submitPasswordStep &&
                                errors &&
                                errors.items &&
                                errors.items.find((item) => item.field === $t('inputs.password') && ['atLeastOneSpecialCharacter'].includes(item.rule))
                            }"
                          >
                            <check-icon
                              v-if="
                                password.length &&
                                !(
                                  errors &&
                                  errors.items &&
                                  errors.items.find((item) => item.field === $t('inputs.password') && ['atLeastOneSpecialCharacter'].includes(item.rule))
                                )
                              "
                              color="rgb(65, 184, 131)"
                              class="register-funnel__main-block__flow__content__form__fields__field__rule__icon"
                              :width="16"
                              :height="16"
                            />

                            {{ $t('inputs.atLeastSpecialCharacter') }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </template>

            <template v-if="currentStep === 3 && !showVegaActivationMessage">
              <div class="register-funnel__main-block__flow__heading register-funnel__main-block__flow__heading--sized">
                <div class="register-funnel__main-block__flow__heading__title">{{ $t('register.greatThatWorked') }}</div>
                <div class="register-funnel__main-block__flow__heading__description">{{ $t('register.personalizeYourExperience') }}</div>
              </div>

              <div class="register-funnel__main-block__flow__content">
                <div class="register-funnel__main-block__flow__content__form">
                  <div class="register-funnel__main-block__flow__content__form__fields">
                    <div class="register-funnel__main-block__flow__content__form__fields__field">
                      <div class="register-funnel__main-block__flow__content__form__fields__field__inner">
                        <input
                          ref="companyName"
                          type="text"
                          class="register-funnel__main-block__flow__content__form__fields__field__input"
                          v-model="companyName"
                          v-validate="'validateStringForSpecialCharacters'"
                          :name="$t('vue.companyName')"
                          :label-placeholder="$t('vue.companyName')"
                          key="company-name"
                        />

                        <div
                          class="register-funnel__main-block__flow__content__form__fields__field__label"
                          :class="{
                            'register-funnel__main-block__flow__content__form__fields__field__label--moved': companyName && companyName.length > 0
                          }"
                          @click="$refs.companyName.focus()"
                        >
                          {{ $t('register.whatIsYourCompanyName') }}
                        </div>
                      </div>

                      <span v-if="errors.first($t('vue.companyName'))" class="text-danger text-lg flex items-center" style="margin-top: 10px">
                        <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first($t('vue.companyName')) }}
                      </span>
                    </div>

                    <div class="register-funnel__main-block__flow__content__form__fields__field">
                      <div class="register-funnel__main-block__flow__content__form__fields__field__inner">
                        <multiselect
                          ref="industry"
                          v-model="industry"
                          :options="industryList"
                          :multiple="false"
                          :close-on-select="true"
                          :clear-on-select="false"
                          :placeholder="''"
                          :selectLabel="''"
                          :selectedLabel="''"
                          :deselectLabel="''"
                          label="name"
                          track-by="name"
                          name="Industry"
                          class="register-funnel__main-block__flow__content__form__fields__field__select"
                          open-direction="bottom"
                          :preserve-search="true"
                          :preselect-first="false"
                          @close="isOpenedIndustrySelect = false"
                          @open="isOpenedIndustrySelect = true"
                        >
                          <template slot="noResult">No results found</template>
                        </multiselect>

                        <div
                          class="register-funnel__main-block__flow__content__form__fields__field__label"
                          :class="{
                            'register-funnel__main-block__flow__content__form__fields__field__label--moved': !!industry || isOpenedIndustrySelect,
                            'register-funnel__main-block__flow__content__form__fields__field__label--over': isOpenedIndustrySelect
                          }"
                          @click="$refs.industry.$el.focus()"
                        >
                          {{ $t('register.whatIndustryAreYouIn') }}
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="industry && industry.name === $i18n.t('register.retail')"
                      class="register-funnel__main-block__flow__content__form__fields__field"
                    >
                      <div class="register-funnel__main-block__flow__content__form__fields__field__inner">
                        <multiselect
                          ref="industryRetail"
                          v-model="industryRetail"
                          :options="industryRetailList"
                          :multiple="false"
                          :close-on-select="true"
                          :clear-on-select="false"
                          :placeholder="''"
                          :selectLabel="''"
                          :selectedLabel="''"
                          :deselectLabel="''"
                          label="name"
                          track-by="name"
                          name="industryRetail"
                          class="register-funnel__main-block__flow__content__form__fields__field__select"
                          open-direction="bottom"
                          :preserve-search="true"
                          :preselect-first="false"
                          @close="isOpenedIndustryRetailSelect = false"
                          @open="isOpenedIndustryRetailSelect = true"
                        >
                          <template slot="noResult">No results found</template>
                        </multiselect>

                        <div
                          class="register-funnel__main-block__flow__content__form__fields__field__label"
                          :class="{
                            'register-funnel__main-block__flow__content__form__fields__field__label--moved': !!industry || isOpenedIndustryRetailSelect,
                            'register-funnel__main-block__flow__content__form__fields__field__label--over': isOpenedIndustryRetailSelect
                          }"
                          @click="$refs.industryRetail.$el.focus()"
                        >
                          {{ $t('register.inWhichArea') }}
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="industry && industry.name === $i18n.t('register.consultingAndCoaching')"
                      class="register-funnel__main-block__flow__content__form__fields__field"
                    >
                      <div class="register-funnel__main-block__flow__content__form__fields__field__inner">
                        <multiselect
                          ref="industryConsulting"
                          v-model="industryConsulting"
                          :options="industryConsultingList"
                          :multiple="false"
                          :close-on-select="true"
                          :clear-on-select="false"
                          :placeholder="''"
                          :selectLabel="''"
                          :selectedLabel="''"
                          :deselectLabel="''"
                          label="name"
                          track-by="name"
                          name="industryConsulting"
                          class="register-funnel__main-block__flow__content__form__fields__field__select"
                          open-direction="bottom"
                          :preserve-search="true"
                          :preselect-first="false"
                          @close="isOpenedIndustryConsultingSelect = false"
                          @open="isOpenedIndustryConsultingSelect = true"
                        >
                          <template slot="noResult">No results found</template>
                        </multiselect>

                        <div
                          class="register-funnel__main-block__flow__content__form__fields__field__label"
                          :class="{
                            'register-funnel__main-block__flow__content__form__fields__field__label--moved': !!industry || isOpenedIndustryConsultingSelect,
                            'register-funnel__main-block__flow__content__form__fields__field__label--over': isOpenedIndustryConsultingSelect
                          }"
                          @click="$refs.industryConsulting.$el.focus()"
                        >
                          {{ $t('register.inWhichArea') }}
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="
                        (industry || industryRetail || industryConsulting) &&
                        [industry.name, industryRetail.name, industryConsulting.name].includes($i18n.t('register.other'))
                      "
                      class="register-funnel__main-block__flow__content__form__fields__field"
                    >
                      <div class="register-funnel__main-block__flow__content__form__fields__field__inner">
                        <input
                          ref="otherIndustry"
                          type="text"
                          class="register-funnel__main-block__flow__content__form__fields__field__input"
                          v-model="otherIndustry"
                          v-validate="'validateStringForSpecialCharacters'"
                          :name="'Other industry'"
                          :label-placeholder="$t('register.other')"
                          :data-vv-as="$t('register.other')"
                        />

                        <div
                          class="register-funnel__main-block__flow__content__form__fields__field__label"
                          :class="{
                            'register-funnel__main-block__flow__content__form__fields__field__label--moved': otherIndustry && otherIndustry.length > 0
                          }"
                          @click="$refs.otherIndustry.focus()"
                        >
                          {{ $t('register.other') }}
                        </div>
                      </div>

                      <span v-if="errors.first('Other industry')" class="text-danger text-lg flex items-center" style="margin-top: 10px">
                        <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first('Other industry') }}
                      </span>
                    </div>

                    <div class="register-funnel__main-block__flow__content__form__fields__field">
                      <div class="register-funnel__main-block__flow__content__form__fields__field__inner">
                        <multiselect
                          ref="interest"
                          v-model="interest"
                          :options="interestList"
                          :multiple="true"
                          :close-on-select="false"
                          :clear-on-select="false"
                          :placeholder="''"
                          :selectLabel="''"
                          :selectedLabel="''"
                          :deselectLabel="''"
                          label="name"
                          track-by="name"
                          name="Interest"
                          class="register-funnel__main-block__flow__content__form__fields__field__select register-funnel__main-block__flow__content__form__fields__field__select-checkbox"
                          open-direction="bottom"
                          :preserve-search="true"
                          :preselect-first="false"
                          @close="isOpenedInterestSelect = false"
                          @open="isOpenedInterestSelect = true"
                        >
                          <template slot="option" slot-scope="{ option }">
                            <div class="register-funnel__main-block__flow__content__form__fields__field__select__checkbox" />

                            {{ option.name }}
                          </template>

                          <template slot="noResult">No results found</template>
                        </multiselect>

                        <div
                          class="register-funnel__main-block__flow__content__form__fields__field__label"
                          :class="{
                            'register-funnel__main-block__flow__content__form__fields__field__label--moved':
                              (!!interest && interest.length) || isOpenedInterestSelect,
                            'register-funnel__main-block__flow__content__form__fields__field__label--over': isOpenedInterestSelect
                          }"
                          @click="$refs.interest.$el.focus()"
                        >
                          {{ $t('register.whatDoYouWantToAchieveWithContactNow') }}
                        </div>
                      </div>
                    </div>

                    <div v-if="isOtherInterest" class="register-funnel__main-block__flow__content__form__fields__field">
                      <div class="register-funnel__main-block__flow__content__form__fields__field__inner">
                        <input
                          ref="otherInterest"
                          type="text"
                          class="register-funnel__main-block__flow__content__form__fields__field__input"
                          v-model="otherInterest"
                          v-validate="'validateStringForSpecialCharacters'"
                          :name="'Other interest'"
                          :label-placeholder="$t('register.other')"
                          :data-vv-as="$t('register.other')"
                        />

                        <div
                          class="register-funnel__main-block__flow__content__form__fields__field__label"
                          :class="{
                            'register-funnel__main-block__flow__content__form__fields__field__label--moved': otherInterest && otherInterest.length > 0
                          }"
                          @click="$refs.otherInterest.focus()"
                        >
                          {{ $t('register.other') }}
                        </div>
                      </div>

                      <span v-if="errors.first('Other interest')" class="text-danger text-lg flex items-center" style="margin-top: 10px">
                        <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first('Other interest') }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <template v-if="currentStep === 3 && showVegaActivationMessage">
              <div class="register-funnel__main-block__flow__heading register-funnel__main-block__flow__heading--sized">
                <div class="register-funnel__main-block__flow__heading__offer">{{ $t('register.vega.exclusiveOffer') }}</div>

                <div class="register-funnel__main-block__flow__heading__title">{{ $t('register.vega.mainTitle') }}</div>
                <div class="register-funnel__main-block__flow__heading__description register-funnel__main-block__flow--no-bottom">
                  {{ $t('register.vega.subTitle') }}
                </div>

                <div class="register-funnel__main-block__flow__vega__benefits">
                  <div class="register-funnel__main-block__flow__vega__benefits__box">
                    <div class="register-funnel__main-block__flow__vega__benefits__box__icon">
                      <VegaIcon />
                    </div>
                    <div class="register-funnel__main-block__flow__vega__benefits__box__header">+300%</div>
                    <div class="register-funnel__main-block__flow__vega__benefits__box__description">
                      {{ $t('register.vega.benefits1') }}
                    </div>
                  </div>
                  <div class="register-funnel__main-block__flow__vega__benefits__box">
                    <div class="register-funnel__main-block__flow__vega__benefits__box__header">+300%</div>
                    <div class="register-funnel__main-block__flow__vega__benefits__box__description">
                      {{ $t('register.vega.benefits2') }}
                    </div>
                  </div>
                  <div class="register-funnel__main-block__flow__vega__benefits__box">
                    <div class="register-funnel__main-block__flow__vega__benefits__box__header">
                      <RatingSuccessIcon :width="30.13" :height="30.13" stroke="#3034F7" />
                    </div>
                    <div
                      class="register-funnel__main-block__flow__vega__benefits__box__description register-funnel__main-block__flow__vega__benefits__box__description--icon"
                    >
                      {{ $t('register.vega.benefits3') }}
                    </div>
                  </div>
                </div>

                <div class="register-funnel__main-block__flow__vega__description">{{ $t('register.vega.description') }}</div>
              </div>

              <div class="register-funnel__main-block__flow__content"></div>
            </template>

            <template v-if="accountCreationSuccessful">
              <div class="mollie-checkout__loading">
                <div class="mollie-checkout__loading__loader">
                  <img src="@/assets/images/register-funnel/check.png" alt="Success" />
                  <div class="mollie-checkout__loading__loader__title">All good!</div>
                  <div class="mollie-checkout__loading__loader__text">We'll redirect you to your account</div>
                </div>
              </div>
            </template>

            <template v-if="currentStep === 4 && !isOpenedFeaturesListOnMobile && company && !company.IS_LOTTERY_WINNER">
              <div class="register-funnel__main-block__flow__heading register-funnel__main-block__flow__heading--sized">
                <div class="register-funnel__main-block__flow__heading__title">{{ $t('register.selectYourPlan') }}</div>
                <div class="register-funnel__main-block__flow__heading__description register-funnel__main-block__flow__heading__description--thin">
                  {{ $t('register.youWontBeChargedUntilAfterYourTrialEnds') }}
                </div>
              </div>
            </template>

            <template v-if="currentStep === 5">
              <div
                class="register-funnel__main-block__flow__heading register-funnel__main-block__flow__heading--sized"
                :class="{
                  'register-funnel__main-block__flow__heading--margin': $i18n.locale === 'de'
                }"
              >
                <div class="register-funnel__main-block__flow__heading__title">{{ $t('register.setupYourAccount') }}</div>

                <div class="register-funnel__main-block__flow__heading register-funnel__main-block__flow__heading--sized">
                  <div class="register-funnel__main-block__flow__heading__description register-funnel__main-block__flow__heading__description--thin">
                    {{ $t('register.youWontBeChargedUntilAfterYourTrialEnds') }}
                  </div>
                </div>
              </div>

              <Mollie
                :btnTitle="$t('vue.goToYourAccount')"
                :subscriptionPlanId="subscriptionPlanId"
                :selectedPlan="selectedPlan"
                :isFirstInvoice="true"
                :supportedPaymentMethods="supportedPaymentMethods"
                :redirectUrl="mollieRedirectUrl"
                :IS_LOTTERY_WINNER="IS_LOTTERY_WINNER"
              />
            </template>
          </div>

          <template v-if="currentStep === 4">
            <MolliePricingPlans
              :isOpenedFeaturesListOnMobile="isOpenedFeaturesListOnMobile"
              :isOpenedFeaturesList="isOpenedFeaturesList"
              :isOpenedBasicFeaturesList="isOpenedBasicFeaturesList"
              :isOpenedProFeaturesList="isOpenedProFeaturesList"
              :isOpenedEnterpriseFeaturesList="isOpenedEnterpriseFeaturesList"
              :featuresList="featuresList"
              @select-plan="selectPlan"
              :isLotteryWinner="company ? Boolean(company.IS_LOTTERY_WINNER) : false"
            />
          </template>

          <div
            v-if="currentStep == 3 && !showVegaActivationMessage && company && !company.IS_LOTTERY_WINNER"
            class="register-funnel__main-block__flow__content__next-button-wrapper"
          >
            <div class="register-funnel__main-block__flow__content__next-button" @click="goToYourAccount(false)" v-if="currentStep !== 5">
              <template>{{ $t('vue.goToYourAccount') }}</template>
              <img src="@/assets/images/register-funnel/forward-arrow.png" alt="Next" class="register-funnel__main-block__flow__content__next-button__icon" />
            </div>
          </div>

          <div
            v-if="currentStep == 3 && showVegaActivationMessage && company && !company.IS_LOTTERY_WINNER"
            class="register-funnel__main-block__flow__content__next-button-wrapper"
          >
            <div class="register-funnel__main-block__flow__content__next-button" @click="goToYourAccount(true)" v-if="currentStep !== 5">
              <template>{{ $t('register.vega.activateVegaButton') }}</template>
              <img src="@/assets/images/register-funnel/forward-arrow.png" alt="Next" class="register-funnel__main-block__flow__content__next-button__icon" />
            </div>
          </div>

          <div
            v-if="(currentStep <= totalCountOfSteps && currentStep != 4 && currentStep !== 3) || (company && company.IS_LOTTERY_WINNER)"
            class="register-funnel__main-block__flow__content__next-button-wrapper"
          >
            <div class="register-funnel__main-block__flow__content__next-button" @click="nextStep" v-if="currentStep !== 5">
              <template>{{ $t('vue.nextStep') }}</template>

              <img src="@/assets/images/register-funnel/forward-arrow.png" alt="Next" class="register-funnel__main-block__flow__content__next-button__icon" />
            </div>
          </div>

          <div v-if="currentStep === 3" class="register-funnel__main-block__footer">
            <div class="text-center">
              <span class="register-funnel__main-block__footer__description register-funnel__main-block__footer__description--hover" @click="skipStep">
                {{ showVegaActivationMessage ? $t('vue.skip') : $t('vue.skipThisStepDoItLater') }}
              </span>
            </div>
          </div>

          <div v-if="currentStep != 4 && !showVegaActivationMessage" class="register-funnel__main-block__footer">
            <div class="register-funnel__main-block__footer__description">
              <template v-if="$i18n.locale === 'de'">
                Mit Abschluss der Registrierung erklärst du dich damit einverstanden, dass wir deine Daten wie in der
                <a
                  href="#"
                  class="register-funnel__main-block__footer__description__link"
                  @click.prevent="
                    openExternalFile(
                      'https://storage.googleapis.com/pathadvice-app.appspot.com/legal/2023/A3100D_Lizenzvertrag_Datenverarbeitung_LetsConnect_DE_20221018.pdf'
                    )
                  "
                >
                  Lizenzvereinbarung und Datenauftragsverarbeitungsvereinbarung
                </a>
                der PATHADVICE International GmbH beschrieben (einschl. US-Datenbezug) verarbeiten.
              </template>
              <template v-else>
                By creating your account, you agree that we may process your data as described in the
                <a
                  href="#"
                  class="register-funnel__main-block__footer__description__link"
                  @click.prevent="
                    openExternalFile(
                      'https://storage.googleapis.com/pathadvice-app.appspot.com/legal/2023/A3100E_LicenseAgreement_DataProcessing_Agreement_LetsConnect_EN_20221018.pdf'
                    )
                  "
                >
                  License Agreement and Data Order Processing Agreement
                </a>
                (including US data reference).
              </template>
            </div>

            <!-- <div v-if="currentStep === 5" class="register-funnel__main-block__footer__trust-service">
              <img src="@/assets/images/register-funnel/truste.png" alt="Truste" />
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div v-if="!showVegaActivationMessage" class="register-funnel__info-block">
      <div class="register-funnel__info-block__inner">
        <img src="@/assets/images/register-funnel/people-1.png" alt="Hi there!" />
        <div class="register-funnel__info-block__title">{{ $t('vue.registerFunnelInfoBlockTitle') }}</div>
        <div class="register-funnel__info-block__description">{{ $t('vue.registerFunnelInfoBlockDescription') }}</div>
      </div>
    </div>
    <div v-if="showVegaActivationMessage" class="register-funnel__info-block">
      <div class="register-funnel__info-block__inner">
        <img src="@/assets/images/register-funnel/vega-analyze.png" width="285" height="207" alt="Vega analyze" />
        <div class="register-funnel__info-block__title">{{ $t('register.vega.rightHeader') }}</div>
        <div class="register-funnel__info-block__description">{{ $t('register.vega.rightDescription') }}</div>
        <div class="register-funnel__info-block__link">
          <a href="https://letsconnect.at" target="_blank">{{ $t('register.vega.rightLink') }}</a>
        </div>
      </div>
    </div>

    <div v-if="isLoadingRegisterFunnel" class="register-funnel__loading">
      <div class="register-funnel__loading__loader">
        <span class="register-funnel__loading__loader__spinner" />
        <div class="register-funnel__loading__loader__text">Processing...</div>
      </div>
    </div>
  </div>
</template>

<script>
import { currency } from '@/mixins/currency'
import { paddle } from '@/mixins/paddle'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

import { mapGetters, mapMutations } from 'vuex'

// VeeValidate
import { Validator } from 'vee-validate'
// Merge all defined languages in the dictionary and sets the current locale.
import validationDictionary from '@/validations/validationDictionary'

import 'vue-multiselect/dist/vue-multiselect.min.css'
import Multiselect from 'vue-multiselect'

import Mollie from '@/views/pages/plans/Mollie.vue'
import MolliePricingPlans from '@/views/pages/plans/MolliePricingPlans.vue'
import FunnelSocialLogin from '@/components/auth/FunnelSocialLogin'
import CheckIcon from '@/components/icons/CheckIcon'
import ArrowLongIcon from '@/components/icons/ArrowLongIcon'
import RatingSuccessIcon from '@/components/icons/ratings/RatingSuccessIcon.vue'
import VegaIcon from '@/components/icons/vega/VegaIcon.vue'

import { commonFunctions } from '@/mixins/commonFunctions'
import LotteryCongratsMessage from './LotteryCongratsMessage.vue'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default {
  components: {
    Mollie,
    MolliePricingPlans,
    Multiselect,
    FunnelSocialLogin,
    CheckIcon,
    ArrowLongIcon,
    LotteryCongratsMessage,
    RatingSuccessIcon,
    VegaIcon
  },

  mixins: [commonFunctions, paddle, currency],

  data: (state) => ({
    isRegistrationProcessComplete: false,
    canShowVegaActivationMessage: true,
    showVegaActivationMessage: false,
    accountCreationSuccessful: false,
    unsubscribeOnAuthStateChanged: null,
    countryCodes: state.$i18n.locale === 'de' ? require('@/assets/countryCodes_de.json') : require('@/assets/countryCodes.json'),
    isLoading: false,
    isLoadingInProccess: false,
    loaderSpinnerText: 'Processing...',
    IS_LOTTERY_WINNER: false,
    isAlreadySelectedSubscriptionPlanId: false,
    M_MONTHLY_FREE: 'M_MONTHLY_FREE',
    M_ANNUAL_FREE: 'M_ANNUAL_FREE',
    M_BASIC_MONTHLY: 'M_BASIC_MONTHLY',
    M_BASIC_ANNUAL: 'M_BASIC_ANNUAL',
    M_PROFESSIONAL_MONTHLY: 'M_PROFESSIONAL_MONTHLY',
    M_PROFESSIONAL_ANNUAL: 'M_PROFESSIONAL_ANNUAL',
    M_ENTERPRISE_MONTHLY: 'M_ENTERPRISE_MONTHLY',
    M_ENTERPRISE_ANNUAL: 'M_ENTERPRISE_ANNUAL',

    paymentMethods: ['invoice', 'card', 'paypal', 'sepa'],

    name: '',
    email: '',
    password: '',
    companyName: '',
    industry: '',
    industryRetail: '',
    industryConsulting: '',
    otherIndustry: '',
    interest: [],
    otherInterest: '',
    isNewsLetterAccepted: false,

    isUniqueEmailError: false,

    isSubmitEmailStep: false,
    submitPasswordStep: false,

    currentStep: 1,
    totalCountOfSteps: 5,

    industryList: [
      {
        name: state.$i18n.t('register.retail')
      },
      {
        name: state.$i18n.t('register.consultingAndCoaching')
      },
      {
        name: state.$i18n.t('register.advertisingAndMedia')
      },
      {
        name: state.$i18n.t('register.financeAndLaw')
      },
      {
        name: state.$i18n.t('register.hotelAndGastronomy')
      },
      {
        name: state.$i18n.t('register.computersAndElectronics')
      },
      {
        name: state.$i18n.t('register.educationAndScience')
      },
      {
        name: state.$i18n.t('register.services')
      },
      {
        name: state.$i18n.t('register.leisureAndTravel')
      },
      {
        name: state.$i18n.t('register.healthAndWellness')
      },
      {
        name: state.$i18n.t('register.cultureAndEvents')
      },
      {
        name: state.$i18n.t('register.businessSupplies')
      },
      {
        name: state.$i18n.t('register.natureAndEnvironment')
      },
      {
        name: state.$i18n.t('register.carsAndTraffic')
      },
      {
        name: state.$i18n.t('register.livingAndFurnishing')
      },
      {
        name: state.$i18n.t('register.buildingAndRenovation')
      },
      {
        name: state.$i18n.t('register.other')
      }
    ],
    industryRetailList: [
      {
        name: state.$i18n.t('register.foodAndBeverages')
      },
      {
        name: state.$i18n.t('register.clothesAndAccessories')
      },
      {
        name: state.$i18n.t('register.homeAndLiving')
      },
      {
        name: state.$i18n.t('register.electronicsAndComputers')
      },
      {
        name: state.$i18n.t('register.sportsAndOutdoors')
      },
      {
        name: state.$i18n.t('register.automotiveAndMotorcycle')
      },
      {
        name: state.$i18n.t('register.other')
      }
    ],
    industryConsultingList: [
      {
        name: state.$i18n.t('register.leisureAndTravel')
      },
      {
        name: state.$i18n.t('register.financeAndLaw')
      },
      {
        name: state.$i18n.t('register.healthAndWellness')
      },
      {
        name: state.$i18n.t('register.insurances')
      },
      {
        name: state.$i18n.t('register.marketingAndBusiness')
      },
      {
        name: state.$i18n.t('register.educationAndScience')
      },
      {
        name: state.$i18n.t('register.other')
      }
    ],
    interestList: [
      {
        name: state.$i18n.t('register.customerAcquisition')
      },
      {
        name: state.$i18n.t('register.qualifyingLeads')
      },
      {
        name: state.$i18n.t('register.gainLeads')
      },
      {
        name: state.$i18n.t('register.conversionRateOptimization')
      },
      {
        name: state.$i18n.t('register.liveChat')
      },
      {
        name: state.$i18n.t('register.livePhoneCustomerService')
      },
      {
        name: state.$i18n.t('register.liveVideoCustomerService')
      },
      {
        name: state.$i18n.t('register.decreaseShoppingCartAbandonment')
      },
      {
        name: state.$i18n.t('register.analyzeAnonymousWebsiteVisitors')
      },
      {
        name: state.$i18n.t('register.transformWebsiteVisitorsIntoLeads')
      },
      {
        name: state.$i18n.t('register.callCenterService')
      },
      {
        name: state.$i18n.t('register.digitalSalesAndQualificationEesources')
      },
      {
        name: state.$i18n.t('register.digitalContactWithoutAWebsite')
      },
      {
        name: state.$i18n.t('register.QRCodesOnProductsForLiveContact')
      },
      {
        name: state.$i18n.t('register.QRCodesInShopWindowsForLiveContact')
      },
      {
        name: state.$i18n.t('register.liveContactLinkInEmailSignature')
      },
      {
        name: state.$i18n.t('register.assistByCoBrowsing')
      },
      {
        name: state.$i18n.t('register.other')
      }
    ],
    isOpenedIndustrySelect: false,
    isOpenedIndustryRetailSelect: false,
    isOpenedIndustryConsultingSelect: false,
    isOpenedInterestSelect: false,

    subscriptionPlanId: '',
    selectedPlan: null,

    isOpenedFeaturesList: false,
    isOpenedBasicFeaturesList: false,
    isOpenedProFeaturesList: false,
    isOpenedEnterpriseFeaturesList: false,

    featuresList: [
      {
        title: state.$i18n.t('features.generalFeatures'),
        items: [
          {
            name: state.$i18n.t('features.includedUsers'),
            description: state.$i18n.t('features.forIndividualsAndTeams'),
            isFree: true,
            isBasic: true,
            isPro: true,
            isEnterprise: true,
            isCustomField: true,
            customTextFree: '5',
            customTextBasic: '5',
            customTextPro: '5',
            customTextEnterprise: state.$i18n.t('pricing.biggerTeams')
          },
          {
            name: state.$i18n.t('features.liveContactWidget'),
            description: state.$i18n.t('features.easyChatPhoneAndVideoCommunicationWithCustomers'),
            isFree: true,
            isBasic: true,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.emailSupport'),
            description: state.$i18n.t('features.getEmailSupportFromContactNowExperts'),
            isFree: true,
            isBasic: true,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.contactFormToCollectLeads'),
            description: state.$i18n.t('features.contactFormToCollectLeadsDescription'),
            isFree: false,
            isBasic: true,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.screensharing'),
            description: state.$i18n.t('features.screensharingDescription'),
            isFree: false,
            isBasic: true,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.fullLiveSupport'),
            description: state.$i18n.t('features.fullLiveSupportDescription'),
            isFree: false,
            isBasic: false,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.aiPoweredPreQualification'),
            description: state.$i18n.t('features.aiPoweredPreQualificationDescription'),
            isFree: true,
            isBasic: true,
            isPro: true,
            isEnterprise: true,
            isCustomField: true,
            customTextFree: state.$i18n.t('features.vegaCostPerVisitor'),
            customTextBasic: state.$i18n.t('features.vegaCostPerVisitor'),
            customTextPro: state.$i18n.t('features.vegaCostPerVisitor'),
            customTextEnterprise: state.$i18n.t('features.vegaCostPerVisitor')
          }
        ]
      },
      {
        title: state.$i18n.t('features.proSolutionsBundle'),
        items: [
          {
            name: state.$i18n.t('features.fullStackCalendar'),
            description: state.$i18n.t('features.appointmentSchedulingAndEasyBooking'),
            isFree: false,
            isBasic: false,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.onlineMeeting'),
            description: state.$i18n.t('features.setUpMeetingsWithMoreParticipants'),
            isFree: false,
            isBasic: false,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.contactViaQrCode'),
            description: state.$i18n.t('features.contactViaQrCodeDescription'),
            isFree: false,
            isBasic: false,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.unlimitedVisitorsIdentification'),
            description: state.$i18n.t('features.unlimitedVisitorsIdentificationDescription'),
            isFree: false,
            isBasic: false,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.ABTesting'),
            description: state.$i18n.t('features.ABTestingDescription'),
            isFree: false,
            isBasic: false,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.customBranding'),
            description: state.$i18n.t('features.customBrandingDescription'),
            isFree: false,
            isBasic: false,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.fullLiveSupport'),
            description: state.$i18n.t('features.fullLiveSupportDescription'),
            isFree: false,
            isBasic: false,
            isPro: true,
            isEnterprise: true
          }
        ]
      },
      {
        title: state.$i18n.t('features.enterpriseSolutionsBundle'),
        items: [
          {
            name: state.$i18n.t('features.routing'),
            description: state.$i18n.t('features.routingDescription'),
            isFree: false,
            isBasic: false,
            isPro: false,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.expertAnalytics'),
            description: state.$i18n.t('features.expertAnalyticsDescription'),
            isFree: false,
            isBasic: false,
            isPro: false,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.prioritySupport'),
            description: state.$i18n.t('features.prioritySupportDescription'),
            isFree: false,
            isBasic: false,
            isPro: false,
            isEnterprise: true
          }
        ]
      }
    ]
  }),

  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company',
      isLoadingRegisterFunnel: 'isLoadingRegisterFunnel'
    }),

    supportedPaymentMethods() {
      if (this.IS_LOTTERY_WINNER) {
        return this.paymentMethods.filter((x) => x === 'card' || x === 'paypal' || x === 'invoice')
      }
      if (this.currency && this.currency.text === 'EUR') {
        return this.paymentMethods.filter((x) => x === 'card' || x === 'paypal' || x === 'invoice')
      } else {
        return this.paymentMethods.filter((x) => x === 'card' || x === 'paypal')
      }
    },

    isAnnualPeriodType() {
      return this.subscriptionPlanType === 'annual'
    },

    isOpenedFeaturesListOnMobile() {
      return this.isOpenedBasicFeaturesList || this.isOpenedProFeaturesList || this.isOpenedEnterpriseFeaturesList
    },

    mollieRedirectUrl() {
      return `${location.origin}/processing-payment`
    },

    isBackButton() {
      return this.currentStep > 1 && !this.showVegaActivationMessage
    },

    isOtherInterest() {
      return this.interest && this.interest.length && this.interest.findIndex((item) => item.name === this.$i18n.t('register.other')) > -1
    }
  },

  watch: {
    async '$route.query'() {
      if (!this.activeUserInfo || !this.activeUserInfo.uid || !this.company) {
        await this.onAuthStateUpdate()
        await this.prepopulateCompanyInfo()
      }
    },
    'company.isRegistrationProcessComplete'() {
      if (this.company.isRegistrationProcessComplete && !this.isRegistrationProcessComplete) {
        window.location = '/incoming-requests'
      }
    }
  },

  async created() {
    /* Localize validator */

    Validator.localize(this.$i18n.locale, validationDictionary[this.$i18n.locale])
    this.parseQueryParameters()
  },

  async mounted() {
    await this.onAuthStateUpdate()
    await this.prepopulateCompanyInfo()

    this.unsubscribeOnAuthStateChanged = firebase.auth().onAuthStateChanged(async (user) => {
      if (user && user.uid && this.$route && this.$route.name === 'page-register' && this.currentStep === 5) {
        await this.onAuthStateUpdate()
      }
    })

    this.$serverBus.$on('toggle-features-list', ({ type, value }) => {
      if (type === 'All') {
        this.isOpenedFeaturesList = value
      } else {
        this[`isOpened${type}FeaturesList`] = value
      }
    })
    this.$serverBus.$on('return-to-all-plans', () => {
      this.returnToAllPlans()
    })

    if (
      this.$route.query.lang &&
      this.$i18n.locale &&
      this.$route.query.lang !== this.$i18n.locale &&
      this.$languagesSuppported.includes(this.$route.query.lang)
    ) {
      this.$i18n.locale = this.$route.query.lang
    }
  },

  beforeDestroy() {
    this.unsubscribeOnAuthStateChanged()
    this.$serverBus.$off('toggle-features-list')
    this.$serverBus.$off('return-to-all-plans')
  },

  methods: {
    ...mapMutations({
      updateCompany: 'UPDATE_COMPANY',
      updateUserInfo: 'UPDATE_USER_INFO',
      updateIsLoadingRegisterFunnel: 'UPDATE_IS_LOADING_REGISTER_FUNNEL'
    }),

    hasNumber(code) {
      return /\d/.test(code)
    },

    customSpinnerLoad(enable, text) {
      if (enable) {
        this.loaderSpinnerText = text
        this.isLoading = enable
        this.isLoadingInProccess = enable
      } else {
        this.loaderSpinnerText = ''
        this.isLoading = enable
        this.isLoadingInProccess = enable
      }
    },

    async prepopulateCompanyInfo() {
      if (this.activeUserInfo && this.activeUserInfo.companyInfo) {
        if (this.activeUserInfo.companyInfo.name) {
          this.companyName = this.activeUserInfo.companyInfo.name
        }

        if (this.activeUserInfo.companyInfo.industry) {
          this.industry = {
            name: this.activeUserInfo.companyInfo.industry
          }
        }

        if (this.activeUserInfo.companyInfo.interest) {
          this.interest = this.activeUserInfo.companyInfo.interest.map((item) => ({ name: item }))
        }

        if (this.activeUserInfo.companyInfo.industryRetail) {
          this.industryRetail = {
            name: this.activeUserInfo.companyInfo.industryRetail
          }
        }

        if (this.activeUserInfo.companyInfo.industryConsulting) {
          this.industryConsulting = {
            name: this.activeUserInfo.companyInfo.industryConsulting
          }
        }

        if (this.activeUserInfo.companyInfo.otherIndustry) {
          this.otherIndustry = this.activeUserInfo.companyInfo.otherIndustry
        }

        if (this.activeUserInfo.companyInfo.otherInterest) {
          this.otherInterest = this.activeUserInfo.companyInfo.otherInterest
        }

        if (
          (this.activeUserInfo.companyInfo.LOTTERY_PARTICIPATING_DATE &&
            this.activeUserInfo.companyInfo.IS_LOTTERY_WINNER &&
            this.activeUserInfo.companyInfo.subscriptionPlanId === this.M_PROFESSIONAL_ANNUAL) ||
          (this.company &&
            this.company.LOTTERY_PARTICIPATING_DATE &&
            this.company.IS_LOTTERY_WINNER &&
            this.company.subscriptionPlanId === this.M_PROFESSIONAL_ANNUAL)
        ) {
          const isValidLotteryTime = await this.checkLotteryTime()

          if (!isValidLotteryTime) {
            this.IS_LOTTERY_WINNER = false
          } else {
            const date = this.company.LOTTERY_PARTICIPATING_DATE || this.activeUserInfo.companyInfo.LOTTERY_PARTICIPATING_DATE
            const today = dayjs().tz(this.$defaultTimezone)
            const diff = today.diff(dayjs(new Date(date.seconds * 1000)), 'day')

            const plans = this.getBaseSubscriptionPlans()

            this.selectedPlan = plans.find((el) => el.id === this.M_PROFESSIONAL_ANNUAL)
            this.IS_LOTTERY_WINNER = diff <= 10
          }
        } else {
          this.IS_LOTTERY_WINNER = false
        }
      }
    },

    async checkLotteryTime() {
      try {
        const value = await this.$db.collection('settings').doc('lottery').get()
        const date = dayjs.unix(value.data().endDate.seconds)
        const isValidLotteryTime = dayjs().isBefore(date)

        return isValidLotteryTime
      } catch (error) {
        return false
      }
    },

    async onAuthStateUpdate() {
      if (this.$route.query.step && parseInt(this.$route.query.step) !== 3 && parseInt(this.$route.query.step) !== 4) {
        return
      }

      this.updateIsLoadingRegisterFunnel(true)

      const firebaseCurrentUser = await firebase.auth().currentUser

      if (firebaseCurrentUser && firebaseCurrentUser.uid) {
        const userDoc = await this.$db.collection('users').doc(firebaseCurrentUser.uid).get()
        const _user = userDoc.data()

        if (_user && _user.company) {
          _user.uid = firebaseCurrentUser.uid

          const companyDoc = await this.$db.collection('company').doc(_user.company).get()
          const _company = companyDoc.data()

          if (_company && (_company.currentInvoiceId || _company.subscriptionId)) {
            const idTokenResult = await firebase.auth().currentUser.getIdTokenResult(true)
            if (idTokenResult && idTokenResult.claims && idTokenResult.claims.role === 'view-only') {
              this.$router.push({ name: 'home' })
            } else {
              this.$router.push({ name: 'incoming-requests' })
            }
          }

          if (companyDoc && companyDoc.id && _company) {
            const _companyInfo = {
              updateKey: Math.random().toString(36).substring(2, 15),
              updated: new Date()
            }

            if (this.IS_LOTTERY_WINNER) {
              _companyInfo.currency = { text: 'EUR', symbol: '€' }
              if (!_company.IS_LOTTERY_WINNER) {
                _companyInfo.IS_LOTTERY_WINNER = true
                _companyInfo.LOTTERY_PARTICIPATING_DATE = new Date()
                _company.IS_LOTTERY_WINNER = true
                _company.LOTTERY_PARTICIPATING_DATE = new Date()
              }
            } else if (this.currency) {
              _companyInfo.currency = this.currency
            }

            _company.id = companyDoc.id

            await firebase.auth().currentUser.getIdTokenResult(true)
            await this.$db.collection('company').doc(_user.company).set(_companyInfo, { merge: true })
            this.updateUserInfo(_user)
            this.updateCompany(_company)

            if (this.$route && this.$route.query && this.$route.query.step && !isNaN(parseInt(this.$route.query.step))) {
              this.currentStep = parseInt(this.$route.query.step)
            }
          }
        }
      }

      this.updateIsLoadingRegisterFunnel(false)
    },

    async parseQueryParameters() {
      if (!(this.$route && this.$route.query)) {
        return
      }

      if (this.$route.query.displayName) {
        this.name = this.$route.query.displayName
      }

      if (this.$route.query.email) {
        try {
          this.email = window.atob(this.$route.query.email)
        } catch (err) {
          /* eslint-disable no-console */
          console.error(err)
        }
      }

      if (this.$route.query.subscriptionPlanId) {
        const subcriptionPlans = [
          this.M_BASIC_MONTHLY,
          this.M_BASIC_ANNUAL,
          this.M_PROFESSIONAL_MONTHLY,
          this.M_PROFESSIONAL_ANNUAL,
          this.M_ENTERPRISE_MONTHLY,
          this.M_ENTERPRISE_ANNUAL
        ]

        if (subcriptionPlans.findIndex((item) => window.atob(this.$route.query.subscriptionPlanId) === item) > -1) {
          this.isAlreadySelectedSubscriptionPlanId = !!this.$route.query.subscriptionPlanId
          this.subscriptionPlanId = window.atob(this.$route.query.subscriptionPlanId)
          const plans = this.getBaseSubscriptionPlans()
          this.selectedPlan = plans.find((el) => el.id === this.subscriptionPlanId)

          if (this.$route.query.isLotteryWinner && this.subscriptionPlanId === this.M_PROFESSIONAL_ANNUAL) {
            const isLotteryWinnerQuery = Boolean(window.atob(this.$route.query.isLotteryWinner))

            if (isLotteryWinnerQuery) {
              const isValidLotteryTime = await this.checkLotteryTime()

              this.IS_LOTTERY_WINNER = isValidLotteryTime

              this.checkRouterStep()
            }
          }
        }
      } else {
        this.checkRouterStep()
      }
    },

    async checkRouterStep() {
      if (this.$route.query.step) {
        await this.onAuthStateUpdate()
        const currentStep = this.$route.query.step <= 4 ? parseInt(this.$route.query.step) : 3
        await this.$vs.loading()

        if (parseInt(this.$route.query.step) >= 3 && this.activeUserInfo && this.activeUserInfo.displayName) {
          this.currentStep = currentStep

          if (currentStep !== 3 && currentStep !== 4) {
            await this.$router.push({ name: 'page-register', query: { ...this.$route.query, step: this.currentStep, lang: this.$i18n.locale } })
          }
        } else {
          this.currentStep = 1

          if (this.$route.query.step !== '1') {
            await this.$router.push({ name: 'page-register', query: { ...this.$route.query, step: this.currentStep, lang: this.$i18n.locale } })
          }
        }

        await this.$vs.loading.close()
      }
    },

    async prevStep() {
      if (this.currentStep < 1) {
        await this.$vs.loading.close()
        return
      }

      if (this.currentStep === 2) {
        this.submitPasswordStep = false
        this.currentStep = this.currentStep - 1

        await this.$vs.loading.close()
      }

      if (this.currentStep === 3) {
        this.logout()
      } else {
        await this.$vs.loading()

        if (
          (this.company && this.company.IS_LOTTERY_WINNER && this.company.subscriptionPlanId === this.M_PROFESSIONAL_ANNUAL) ||
          (this.activeUserInfo &&
            this.activeUserInfo.companyInfo &&
            this.activeUserInfo.companyInfo.IS_LOTTERY_WINNER &&
            this.activeUserInfo.companyInfo.subscriptionPlanId === this.M_PROFESSIONAL_ANNUAL)
        ) {
          this.currentStep = this.currentStep - 2
        } else {
          this.currentStep = this.currentStep - 1
        }
        if (this.currentStep < 1) this.currentStep = 1
        await this.$router.push({ name: 'page-register', query: { step: this.currentStep, lang: this.$i18n.locale } })
        await this.$vs.loading.close()
      }
    },

    async nextStep() {
      await this.$vs.loading()
      const result = await this.$validator.validateAll()

      if (this.currentStep === 2) {
        this.submitPasswordStep = true
      }

      if (!result) {
        await this.$vs.loading.close()
        return
      }
      await this.$vs.loading.close()

      if (this.currentStep === 1) {
        await this.$vs.loading()

        this.isSubmitEmailStep = true
        this.isUniqueEmailError = false

        const checkEmailExists = this.$functions.httpsCallable('checkEmailExists')

        try {
          const res = await checkEmailExists({
            email: this.email,
            isAuthenticationCheck: true
          })

          if (res.data) {
            this.isUniqueEmailError = true
          } else {
            this.logAnalyticsEvent(this.$AnalyticsEvents.REGISTER_ENTERED_NAME_AND_EMAIL, {
              name: this.name,
              email: this.email
            })
            this.currentStep = this.currentStep + 1
            await this.$router.push({ name: 'page-register', query: { step: this.currentStep, lang: this.$i18n.locale } })
          }
        } catch (error) {
          this.$vs.notify({
            time: 8800,
            title: this.$i18n.t('vue.error'),
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }

        await this.$vs.loading.close()
      } else if (this.currentStep === 2) {
        await this.registerUser()
      } else if (this.currentStep === 3) {
        await this.$vs.loading()
        await this.saveCompanyInfo()

        if (
          this.isAlreadySelectedSubscriptionPlanId ||
          (this.company && this.company.IS_LOTTERY_WINNER && this.company.subscriptionPlanId === this.M_PROFESSIONAL_ANNUAL) ||
          (this.activeUserInfo.companyInfo &&
            this.activeUserInfo.companyInfo.IS_LOTTERY_WINNER &&
            this.activeUserInfo.companyInfo.subscriptionPlanId === this.M_PROFESSIONAL_ANNUAL)
        ) {
          this.currentStep = this.currentStep + 2
        } else {
          this.currentStep = this.currentStep + 1
        }

        if (this.isAlreadySelectedSubscriptionPlanId) {
          await this.$vs.loading.close()
        }

        await this.$router.push({ name: 'page-register', query: { step: this.currentStep, lang: this.$i18n.locale } })

        if (!this.isAlreadySelectedSubscriptionPlanId) {
          await this.$vs.loading.close()
        }
      } else if (this.currentStep !== 2) {
        if (this.currentStep !== 4) {
          await this.$vs.loading()
        }

        this.currentStep = this.currentStep + 1

        await this.$router.push({ name: 'page-register', query: { step: this.currentStep, lang: this.$i18n.locale } })

        if (this.currentStep !== 5) {
          await this.$vs.loading.close()
        }
      }
    },

    async saveCompanyInfo(activateVega = false) {
      if (!this.activeUserInfo || !this.activeUserInfo.company) {
        return
      }

      const companyName = this.companyName && this.companyName.trim().length > 0 ? this.companyName : this.activeUserInfo.displayName

      const _company = {
        name: companyName,
        industry: this.industry && this.industry.name ? this.industry.name : null,
        interest: this.interest && this.interest.length ? this.interest.map((item) => item.name) : null,
        industryRetail: this.industryRetail && this.industryRetail.name ? this.industryRetail.name : null,
        industryConsulting: this.industryConsulting && this.industryConsulting.name ? this.industryConsulting.name : null,
        otherIndustry: this.otherIndustry,
        otherInterest: this.isOtherInterest ? this.otherInterest : '',
        updateKey: Math.random().toString(36).substring(2, 15),
        updated: new Date()
      }

      if (activateVega) {
        _company.isVegaAccount = true
        _company.vegaIsOnTrial = true
        _company.vegaExpiryDate = dayjs().utc().add(10, 'days').toDate()
        _company.vegaActivationDate = dayjs().utc().toDate()
      }

      if (this.company.country) {
        _company.country = this.company.country
      }
      _company.id = this.activeUserInfo.company
      this.isRegistrationProcessComplete = true
      this.updateCompany(_company)
      await this.$db.collection('company').doc(this.activeUserInfo.company).set(_company, { merge: true })

      this.logAnalyticsEvent(this.$AnalyticsEvents.REGISTER_ACCOUNT_CREATED, {
        name: this.name,
        email: this.email,
        companyName
      })
    },

    async goToYourAccount(activateVega = false) {
      const companyName = this.companyName && this.companyName.trim().length > 0 ? this.companyName : this.activeUserInfo.displayName
      const data = {
        name: companyName,
        industry: this.industry && this.industry.name ? this.industry.name : null,
        interest: this.interest && this.interest.length ? this.interest.map((item) => item.name) : null,
        industryRetail: this.industryRetail && this.industryRetail.name ? this.industryRetail.name : null,
        industryConsulting: this.industryConsulting && this.industryConsulting.name ? this.industryConsulting.name : null,
        otherIndustry: this.otherIndustry,
        otherInterest: this.isOtherInterest ? this.otherInterest : ''
      }
      this.logAnalyticsEvent(this.$AnalyticsEvents.REGISTER_GO_TO_YOUR_ACCOUNT, data)

      await this.saveCompanyInfo(activateVega)

      if (this.canShowVegaActivationMessage && !this.showVegaActivationMessage) {
        this.showVegaActivationMessage = true
        return
      }

      this.accountCreationSuccessful = true

      setTimeout(() => {
        this.$router.push({ name: 'dialog-after-successfull-registration', params: { from: 'page-register' } })
      }, 3000)
    },

    async skipStep() {
      if (this.currentStep === 3 && !this.showVegaActivationMessage) {
        this.showVegaActivationMessage = true
        this.logAnalyticsEvent(this.$AnalyticsEvents.REGISTER_SKIP_ACCOUNT_INFO_STEP)
        return
      }

      if (this.currentStep === 3 && this.showVegaActivationMessage) {
        /* NEW FLOW: TRIAL USERS */
        /* IN NEW FLOW, there is no Step 4 except for Lottery Winners. Accounts by default have paymentStatus 'on-trial' */

        this.logAnalyticsEvent(this.$AnalyticsEvents.REGISTER_SKIP_VEGA_ACTIVATION_STEP)
        this.isRegistrationProcessComplete = true
        this.$db.collection('company').doc(this.activeUserInfo.company).set(
          {
            isVegaAccount: true,
            vegaIsOnTrial: false,
            vegaActivationDate: this.$firebase.firestore.FieldValue.delete(),
            vegaExpiryDate: this.$firebase.firestore.FieldValue.delete(),
            isRegistrationProcessComplete: true
          },
          { merge: true }
        )
        this.accountCreationSuccessful = true
        setTimeout(() => {
          this.$router.push({ name: 'dialog-after-successfull-registration', params: { from: 'page-register' } })
        }, 3000)
      }

      // if (this.company && !this.company.IS_LOTTERY_WINNER) {
      //   /* NEW FLOW: TRIAL USERS */
      //   /* IN NEW FLOW, there is no Step 4 except for Lottery Winners. Accounts by default have paymentStatus 'on-trial' */
      //   this.accountCreationSuccessful = true
      //   setTimeout(() => {
      //     this.$router.push({ name: 'dialog-after-successfull-registration', params: { from: 'page-register' } })
      //   }, 3000)
      // } else {
      //   await this.$vs.loading()
      //   if (this.currentStep === 3 && (this.isAlreadySelectedSubscriptionPlanId || this.IS_LOTTERY_WINNER)) {
      //     this.currentStep = this.currentStep + 2
      //   } else {
      //     this.currentStep = this.currentStep + 1
      //   }
      //   await this.$router.push({ name: 'page-register', query: { step: this.currentStep, lang: this.$i18n.locale } })
      //   await this.$vs.loading.close()
      // }
    },

    countPriceSavings(montlyPrice, annualPrice) {
      const monthlyTotal = montlyPrice * 12
      const annualTotal = annualPrice * 12
      return monthlyTotal - annualTotal
    },

    openExternalFile(path) {
      const today = new Date()
      const iToday = new Date(`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`)
      window.open(`${path}?v=${iToday.getTime()}`, '_blank')
    },

    async checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: this.$i18n.t('vue.loginAttempt'),
          text: this.$i18n.t('vue.youAreAlreadyLoggedIn'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })

        const idTokenResult = await this.$firebase.auth().currentUser.getIdTokenResult(true)
        if (idTokenResult && idTokenResult.claims && idTokenResult.claims.role === 'view-only') {
          this.$router.push({ name: 'home', params: { from: 'page-register' } })
        } else {
          this.$router.push({ name: 'incoming-requests', params: { from: 'page-register' } })
        }
      }
      return true
    },
    async registerUser() {
      // If user is already login return
      if (!this.checkLogin()) return

      this.updateIsLoadingRegisterFunnel(true)

      const names = this.name.split(' ')
      const firstname = names[0] || ''
      names.shift()
      const lastname = names.join(' ') || ''
      const payload = {
        userDetails: {
          displayName: this.name,
          firstname,
          lastname,
          email: this.email,
          password: this.password,
          isNewsLetterAccepted: this.isNewsLetterAccepted,
          language: this.$languagesSuppported.includes(navigator.language.slice(0, 2)) ? navigator.language.slice(0, 2) : this.$defaultLanguage,
          IS_LOTTERY_WINNER: this.IS_LOTTERY_WINNER
        },
        notify: this.$vs.notify
      }

      /* Reseller Program Token Id */
      const tokenID = this.getParameterByName('tokenID') || this.getCookie('tokenID')
      if (tokenID) {
        payload.tokenID = tokenID
      }
      /* SXID */
      const sxid = this.getParameterByName('sxid')
      if (sxid) {
        payload.userDetails.sxid = sxid
      }

      /* Used for Auto creation of company if cookies exist */
      const afid = this.getCookie('afid')
      const cpid = this.getCookie('cpid')
      if (afid) {
        payload.afid = afid
        this.deleteCookie('afid')
        if (cpid) {
          payload.cpid = cpid
          this.deleteCookie('cpid')
        }
      }
      /* Post Affiliate Pro AffiliateID and VisitorID*/
      const papAffiliateID = this.getCookie('PAPAffiliateId')
      const papVisitorID = this.getCookie('PAPVisitorId')

      if (papAffiliateID) {
        payload.papAffiliateID = papAffiliateID
      }
      if (papVisitorID) {
        payload.papVisitorID = papVisitorID
      }

      const locationSearch = this.getCookie('locationSearch')
      const urlParams = new URLSearchParams(locationSearch)
      const entries = urlParams.entries()
      const sources = []
      for (const entry of entries) {
        const source = {}
        source[entry[0]] = entry[1]
        sources.push(source)
      }
      payload.sources = sources

      try {
        const response = await this.$store.dispatch('auth/registerUser', payload)

        if (response && response.step) {
          this.currentStep = response.step

          this.logAnalyticsEvent(this.$AnalyticsEvents.REGISTER_REGISTERED_USER, {
            name: this.name,
            email: this.email
          })

          await this.setLotteryData()
          this.updateIsLoadingRegisterFunnel(false)
        } else {
          this.updateIsLoadingRegisterFunnel(false)
        }
      } catch (err) {
        this.updateIsLoadingRegisterFunnel(false)
        if (err.code === 'auth/email-already-in-use') {
          this.$vs.notify({
            time: 8800,
            title: this.$i18n.t('vue.error'),
            text: this.$i18n.t('vue.emailAlreadyInUse'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        } else {
          this.$vs.notify({
            time: 8800,
            title: this.$i18n.t('vue.error'),
            text: err.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },

    async setLotteryData() {
      const isLotteryFunnel = this.IS_LOTTERY_WINNER && this.subscriptionPlanId && this.activeUserInfo.company && this.activeUserInfo.email

      if (!isLotteryFunnel) {
        return
      }

      try {
        const plans = this.getBaseSubscriptionPlans()

        this.selectedPlan = plans.find((el) => el.id === this.subscriptionPlanId)

        const data = {
          IS_LOTTERY_WINNER: true,
          subscriptionPlanId: this.subscriptionPlanId,
          LOTTERY_PARTICIPATING_DATE: new Date(),
          currency: { text: 'EUR', symbol: '€' }
        }

        await this.$db.collection('company').doc(this.activeUserInfo.company).set(data, { merge: true })
      } catch (error) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
    },

    async logout() {
      const vm = this
      vm.$vs.loading()

      /* Remove user from realtime db */
      const ref = firebase.database().ref(`/users/${this.activeUserInfo.company}/${this.activeUserInfo.uid}`)
      await ref.remove()
      // if user is logged in via firebase
      const firebaseCurrentUser = firebase.auth().currentUser

      if (firebaseCurrentUser) {
        await firebase.auth().signOut()
      }
      //this.$acl.change('admin')[]
      vm.$store.commit('REMOVE_USER_INFO', {
        closeAnimation: this.$vs.loading.close
      })
      setTimeout(() => {
        this.updateCompany(null)

        this.name = ''
        this.email = ''
        this.password = ''
        this.companyName = ''
        this.industry = ''
        this.industryRetail = ''
        this.industryConsulting = ''
        this.otherIndustry = ''
        this.interest = []
        this.otherInterest = ''
        this.currentStep = 1

        this.$router.push({ name: 'page-register', query: { step: 1, lang: this.$i18n.locale } })
      }, 200)
    },

    async selectPlan(name) {
      await this.$vs.loading()

      const plans = this.getBaseSubscriptionPlans()
      this.subscriptionPlanId = name
      this.selectedPlan = plans.find((el) => el.id === name)
      this.currentStep = this.currentStep + 1

      await this.$vs.loading.close()

      await this.$router.push({ name: 'page-register', query: { step: this.currentStep, lang: this.$i18n.locale } })
    },

    returnToAllPlans() {
      this.isOpenedBasicFeaturesList = false
      this.isOpenedProFeaturesList = false
      this.isOpenedEnterpriseFeaturesList = false
    }
  },

  filters: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },

    generateRightTranslations(string, locale) {
      return locale === 'de' ? `Sparen ${string}` : `${string} Save`
    }
  }
}
</script>

<style lang="scss">
.register-funnel__main-block__flow__content__form__fields__field__select {
  min-height: 60px;
  .multiselect__tag {
    color: #262629;
    background: #def1fe;
    &-icon {
      &:hover {
        background: #82ceff;
      }
    }
  }
  .multiselect__option {
    text-transform: capitalize;

    &.multiselect__option--highlight {
      color: #262629;
      background: #f1f9ff;
    }
  }

  .multiselect {
    &__tags {
      padding-top: 9px;
      min-height: 60px;
    }

    &__select {
      height: 55px;
    }

    &__single,
    &__input {
      padding-top: 10px;
    }

    &__content-wrapper {
      min-height: 200px !important;
    }
  }

  &-checkbox {
    .multiselect__option {
      display: flex;
      padding-left: 12px;

      &--selected {
        .register-funnel__main-block__flow__content__form__fields__field__select__checkbox {
          background-image: url('../../../assets/images/pages/login/Vector-7.svg');
          background-position: 50% 50%;
          background-size: auto;
          background-repeat: no-repeat;
          background-color: rgb(130, 206, 255);
          border-color: rgb(130, 206, 255);
        }
      }
    }
  }

  &__checkbox {
    margin-right: 12px;
    width: 16px;
    min-width: 16px;
    height: 16px;
    min-height: 16px;
    border: 1px solid rgb(217, 219, 221);
    border-radius: 4px;
  }
}
</style>

<style scoped lang="scss">
@font-face {
  font-family: 'Larsseit-Light';
  src: url('../../../assets/fonts/Larsseit/Larsseit-Light.woff');
}

@font-face {
  font-family: 'Larsseit-Regular';
  src: url('../../../assets/fonts/Larsseit/Larsseit-Regular.woff');
}

@font-face {
  font-family: 'Larsseit-Medium';
  src: url('../../../assets/fonts/Larsseit/Larsseit-Medium.woff');
}

@font-face {
  font-family: 'Larsseit-Bold';
  src: url('../../../assets/fonts/Larsseit/Larsseit-Bold.woff');
}

@font-face {
  font-family: 'Larsseit-Extra-Bold';
  src: url('../../../assets/fonts/Larsseit/Larsseit-Extra-Bold.woff');
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.register-funnel {
  display: flex;
  width: 100%;
  min-height: 100vh;
  font-feature-settings: 'ss01' on;
  font-family: 'Larsseit-Regular';

  &__main-block {
    padding-bottom: 20px;
    width: calc(100% - 400px);
    min-width: 700px;
    background: rgb(255, 255, 255);

    @media (max-width: 991px) {
      width: 100%;
      min-width: auto;
    }

    &__inner {
      display: flex;
      flex-direction: column;
      margin: auto;
      padding: 0 20px;
      max-width: 850px;
      height: 100%;
    }

    &__heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      padding: 19px 0 20px;
      width: 100%;
      max-width: 700px;

      @media (max-width: 991px) {
        padding: 15px 0 20px;
      }

      &__logo {
        &-wrapper {
          display: flex;
          align-items: center;
        }

        &-img {
          width: 150px;

          @media (max-width: 991px) {
            height: 49px;
          }
        }
      }

      &__auth {
        display: flex;
        align-items: center;

        &__text {
          color: rgb(83, 83, 91);
          font-family: 'Larsseit-Regular';
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 0.05px;

          @media (max-width: 991px) {
            display: none;
          }
        }

        &__icon {
          margin-left: 13px;
          width: 11px;
        }

        &__link {
          margin-left: 5px;
          color: rgb(48, 52, 247);
          font-family: 'Larsseit-Bold';
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 0.05px;

          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }

    &__body {
      position: relative;
    }

    &__flow {
      position: relative;
      margin: 55px auto 0;
      width: 480px;
      max-width: 100%;

      @media (max-width: 991px) {
        margin-top: 0;
      }

      &--sized {
        width: 810px;
      }

      &--no-bottom {
        margin-bottom: 0px !important;
      }

      &__action {
        &-buttons {
          position: absolute;
          top: -36px;
          left: 0;

          @media (max-width: 991px) {
            top: -50px;
            right: 0;
            left: auto;
          }
        }

        &-button {
          display: flex;
          align-items: center;
          color: rgb(135, 136, 148);
          font-family: 'Larsseit-Bold';
          font-size: 18px;
          line-height: 18px;
          letter-spacing: -0.1px;

          &:hover {
            cursor: pointer;
          }
        }
      }

      &__progress-bar {
        width: 0px;
        height: 100%;
        background: #82ceff;
        border-radius: 2.5px;
        transition: width 0.2s;

        &-wrapper {
          width: 100%;
          height: 5px;
          background: #eff1f3;
          border-radius: 2.5px;
          overflow: hidden;
        }
      }

      &__heading {
        margin: 45px auto 0;

        @media (max-width: 991px) {
          margin-top: 20px;
          max-width: 240px;
        }

        &--sized {
          @media (max-width: 991px) {
            max-width: 400px;
          }
        }

        &--margin {
          margin-bottom: 64px;
        }

        &__offer {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 1px;
          color: #82ceff;
          font-family: 'Larsseit-Bold';
          text-align: center;
          margin-bottom: 8px;
          text-transform: uppercase;

          @media (max-width: 991px) {
            font-size: 14px;
            line-height: 32px;
            letter-spacing: 1px;
          }
        }

        &__title {
          color: rgb(38, 38, 41);
          font-family: 'Larsseit-Bold';
          font-size: 32px;
          line-height: 40px;
          text-align: center;
          letter-spacing: -0.177778px;

          &--small {
            margin: 0 auto;
            max-width: 335px;
          }

          @media (max-width: 991px) {
            font-size: 30px;
            line-height: 36px;
            letter-spacing: -0.166667px;
          }
        }

        &__description {
          margin-top: 16px;
          margin-bottom: 64px;
          color: rgb(38, 38, 41);
          font-family: 'Larsseit-Medium';
          font-size: 20px;
          line-height: 22px;
          letter-spacing: -0.111111px;
          text-align: center;

          @media (max-width: 991px) {
            margin-bottom: 38px;
          }

          &--thin {
            color: rgb(83, 83, 91);
            font-family: 'Larsseit-Regular';
            font-size: 20px;
            line-height: 26px;
            letter-spacing: -0.111111px;
          }
        }
      }

      &__vega {
        &__benefits {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-top: 33px;

          &__box {
            position: relative;
            background: #42b5ff;
            box-shadow: 1.43462px 3.58654px 7.17308px rgba(16, 36, 61, 0.25);
            border-radius: 8.6077px;
            width: 114.77px;
            height: 114.77px;

            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            &__icon {
              position: absolute;
              top: -15px;
              left: -27px;
            }

            &__header {
              font-style: normal;
              font-weight: 700;
              font-size: 23.6712px;
              line-height: 32px;
              letter-spacing: -0.131506px;
              font-family: 'Larsseit-Bold';

              color: #3034f7;
              margin-top: 24.39px;
            }

            &__description {
              font-style: normal;
              font-weight: 400;
              font-size: 10.0423px;
              line-height: 14px;
              text-align: center;
              letter-spacing: -0.0717308px;
              font-family: 'Larsseit-Regular';

              color: #ffffff;
              margin-top: 7.45px;

              &--icon {
                margin-top: 2.45px !important;
              }
            }
          }

          &__box:not(:last-child) {
            margin-right: 14.35px;
          }
        }

        &__description {
          margin-top: 30px;
          color: rgb(135, 136, 148);
          font-size: 15px;
          line-height: 22px;
          text-align: center;
          letter-spacing: -0.0833333px;
          font-family: 'Larsseit-Regular';
        }
      }

      &__content {
        &__separator {
          position: relative;
          margin: 30px 0;
          color: rgb(83, 83, 91);
          font-size: 18px;
          line-height: 20px;
          letter-spacing: -0.1px;
          text-align: center;

          @media (max-width: 991px) {
            margin: 20px 0;
            font-size: 16px;
          }

          &::before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            height: 1px;
            width: 100%;
            background: #d9dbdd;
          }

          &__label {
            display: inline-block;
            position: relative;
            padding: 0 14px;
            background: rgb(255, 255, 255);
          }
        }

        &__form {
          &__fields {
            &__field {
              margin-top: 28px;

              @media (max-width: 991px) {
                margin-top: 15px;
              }

              &:first-of-type {
                margin-top: 0;
              }

              &__inner {
                position: relative;
              }

              &__label {
                position: absolute;
                top: 50%;
                left: 20px;
                padding: 0 2px;
                touch-action: none;
                color: #878894;
                font-size: 20px;
                line-height: 20px;
                letter-spacing: -0.111111px;
                background: #ffffff;
                transform: translateY(-50%);
                max-width: calc(100% - 50px);

                &--moved {
                  top: 0;
                  left: 15px;
                  font-family: 'Larsseit-Bold';
                  font-size: 15px;
                  line-height: 20px;
                  letter-spacing: -0.0833333px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  transition: all 0.2s linear;
                }

                &--over {
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  z-index: 98;
                }
              }

              &__input {
                padding: 5px 20px;
                width: 100%;
                height: 60px;
                color: #35495e;
                font-family: 'Larsseit-Regular';
                font-size: 16px;
                line-height: 18px;
                letter-spacing: -0.111111px;
                background: rgb(255, 255, 255);
                border: 1px solid rgb(217, 219, 221);
                border-radius: 8px;
                /* transition is using for preventing the autofill styles of the input */
                transition: background-color 99999999ms;

                &:focus + .register-funnel__main-block__flow__content__form__fields__field__label,
                &:-webkit-autofill + .register-funnel__main-block__flow__content__form__fields__field__label {
                  top: 0;
                  left: 15px;
                  font-family: 'Larsseit-Bold';
                  font-size: 15px;
                  line-height: 20px;
                  letter-spacing: -0.0833333px;
                  transition: all 0.2s linear;
                }
              }

              &__rules-wrapper {
                display: flex;
                flex-wrap: wrap;
                margin-top: 24px;

                @media (max-width: 991px) {
                  flex-direction: column;
                  align-items: flex-start;
                }
              }

              &__rule {
                display: inline-flex;
                align-items: center;
                margin-top: 5px;
                margin-right: 4px;
                padding: 4px 14px;
                color: #538fb5;
                font-family: 'Larsseit-Medium';
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0.04375px;
                background: #e8f6ff;
                border-radius: 12px;

                &__icon {
                  margin-right: 5px;
                }

                &--valid {
                  color: rgb(65, 184, 131);
                  background: rgba(65, 184, 131, 0.3);
                }

                &--invalid {
                  color: rgb(181, 101, 102);
                  background: rgb(255, 207, 207);
                }
              }
            }
          }
        }

        &__next-button {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px 43px;
          color: rgb(255, 255, 255);
          font-family: 'Larsseit-Bold';
          font-size: 20px;
          line-height: 18px;
          letter-spacing: -0.111111px;
          background: rgb(48, 52, 247);
          border: none;
          border-radius: 8px;

          @media (max-width: 991px) {
            width: 100%;
            max-width: 480px;
          }

          &:hover {
            cursor: pointer;
            background: rgba(48, 52, 247, 0.9);
          }

          &-wrapper {
            display: flex;
            justify-content: center;
            margin-top: 50px;

            @media (max-width: 991px) {
              margin-top: 20px;
            }
          }

          &__icon {
            margin-left: 17px;
            width: 20px;
          }
        }
      }
    }

    &__footer {
      margin-top: 28px;

      @media (max-width: 991px) {
        margin-top: 20px;
      }

      &__description {
        color: rgb(135, 136, 148);
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.0833333px;

        &__link {
          display: inline-block;
          color: rgb(48, 52, 247);

          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }

        &--hover {
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      &__trust-service {
        margin-top: 40px;
        text-align: center;
      }
    }
  }

  &__info-block {
    padding: 0 15px;
    width: 400px;
    background: rgb(245, 250, 253);

    &--vega-block {
      width: 450px !important;
    }

    @media (max-width: 991px) {
      display: none;
    }

    &__inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      padding-top: 285px;
      max-width: 235px;
      height: 100%;
    }

    &__title {
      margin-top: 16px;
      color: rgb(38, 38, 41);
      font-family: 'Larsseit-Bold';
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.0888889px;
    }

    &__description {
      color: rgb(83, 83, 91);
      font-size: 15px;
      line-height: 21px;
      text-align: center;
      letter-spacing: -0.0833333px;
    }

    &__link {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.05px;
      color: #3034f7;
      text-align: center;
      margin-top: 20px;
    }
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.95);
    z-index: 99;

    &__loader {
      text-align: center;

      &__spinner {
        display: inline-block;
        width: 70px;
        height: 70px;
        border: 10px solid rgb(222, 241, 254);
        border-bottom-color: rgb(66, 181, 255);
        border-radius: 50%;
        animation: rotation 1s linear infinite;
      }

      &__title {
        margin-top: 28px;
        color: rgb(38, 38, 41);
        font-family: 'Larsseit-Bold';
        font-size: 28px;
        line-height: 26px;
        text-align: center;
        letter-spacing: -0.155556px;
      }

      &__text {
        margin-top: 6px;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        letter-spacing: -0.111111px;
        color: rgb(83, 83, 91);
      }
    }
  }
}
</style>
